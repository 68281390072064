import request from './requestWrapper';

// Types
import {FiltersPaginationType} from '../types/SharedTypes';
import {AllianceStockType} from "../types/AllianceStockTypes";
import {ActivateCatalogType} from '../types/CatalogTypes';

function getParams() {
  return request({
    url: 'catalog/getParams',
    method: 'GET',
  });
}
function getDetail(model: string) {
  return request({
    url: `catalog/getDetail/${model}`,
    method: 'GET',
  });
}
function getTemplate() {
  return request({
    url: 'catalog/getTemplate',
    method: 'GET',
  });
}
function getAll(pagination?: FiltersPaginationType) {
  return request({
    url: 'catalog/getAll',
    method: 'POST',
    data: pagination
  });
}
function downloadCatalog(formData: FormData) {
  return request({
    url: 'catalog/getAllienceCatalog',
    method: 'POST',
    data: formData
  });
}
function upload(formData: FormData) {
  return request({
    url: 'catalog/upload',
    method: 'POST',
    data: formData
  });
}
function update(stock: AllianceStockType) {
  return request({
    url: 'catalog/update',
    method: 'PUT',
    data: stock
  });
}
function activateCatalog(data: ActivateCatalogType) {
  return request({
    url: 'catalog/activateCatalog',
    method: 'PUT',
    data: data
  });
}

export default {
  getParams, getDetail, getTemplate, getAll,
  downloadCatalog, upload, update, activateCatalog
}