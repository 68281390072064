// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import catalogServices from '../../../api/catalogServices';

// Types
import {catalogTypes} from '../types';
import {FiltersPaginationType} from '../../../types/SharedTypes';
import {AllianceStockType} from "../../../types/AllianceStockTypes";
import {ActivateCatalogType} from '../../../types/CatalogTypes';

// Constants
import baseURL from '../../../api/baseURL';

// Components and Subcomponents
import {ERROR_MODAL, SUCCESS_MODAL, SUCCESS_MODAL_ON_OK} from '../../../components/subComponents/responseModals';

// Utils
import {reloadWindow} from "../../../utils/generalUtils";
import {base64ToFile} from '../../../utils/functions';
import {routes} from '../../../utils/routes';


export const getParams = (pagination?: FiltersPaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  catalogServices.getParams()
    .then((response) => {
      dispatch({
        type: catalogTypes.GET_CATALOG_PARAMS,
        catalogParams: response.data
      });
      pagination && dispatch(getAll(pagination));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const getDetail = (model: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  catalogServices.getDetail(model)
    .then((response) => {
      dispatch({
        type: catalogTypes.GET_PRODUCT_DETAIL,
        productDetail: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const getAll = (pagination?: FiltersPaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  catalogServices.getAll(pagination)
    .then((response) => {
      dispatch({
        type: catalogTypes.GET_CATALOG,
        catalogResponse: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const downloadCatalog = (formData: FormData) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  catalogServices.downloadCatalog(formData)
    .then((response) => {
      base64ToFile(response.data.file, response.data.fileName);
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const upload = (formData: FormData) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  catalogServices.upload(formData)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const getCatalogTemplate = () => async (dispatch: AppDispatch) => {  
  dispatch(setLoading(true));
  catalogServices.getTemplate()
    .then((response) => {
      window.open(baseURL + response.data.excelUrl);
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const update = (stock: AllianceStockType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  catalogServices.update(stock)
    .then((response) => {
      SUCCESS_MODAL_ON_OK(response.data.status, () => reloadWindow());
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const activateCatalog = (data: ActivateCatalogType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  catalogServices.activateCatalog(data)
    .then(() => {
      window.location.href = routes.catalog.root;
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
